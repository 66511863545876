/* navstyle.css */
.custom-navbar {
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .custom-navbar .nav-link,
  .custom-navbar .navt {
    color: #333 !important;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .custom-navbar .nav-link:hover,
  .custom-navbar .navt:hover {
    color: #007bff !important;
  }
  
  .custom-navbar .nav-link.text-center,
  .custom-navbar .navt.text-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 15px;
  }
  
  .custom-navbar .nav-dropdown .dropdown-menu {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .custom-navbar .nav-dropdown .dropdown-menu .dropdown-item {
    color: #333;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .custom-navbar .nav-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #f8f9fa;
    color: #007bff;
  }
  
  /* Navbar toggle customization */
  .custom-navbar .navbar-toggler {
    border-color: transparent;
  }
  
  .custom-navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' linecap='round' linejoin='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  


  /* slider */
/* Ensure the slider container and images are responsive */
.slider-fullscreen {
  width: 100%;
  overflow: hidden;
}

.slider-image {
  width: 100%;
  display: block; /* Remove default inline-block space */
  object-fit: cover; /* Ensure image covers the area without distortion */
}

/* Adjust max-height based on different screen sizes */
@media (max-width: 1024px) {
  .slider-fullscreen {
    height: 50%;
  }
  .slider-image {
    max-height: 500px;
    object-fit: cover; /* Adjust as needed for tablets */
  }
}

@media (max-width: 600px) {
  .slider-fullscreen {
    height: 50%;
  }
  .slider-image {
    max-height: 200px;
    object-fit: cover; /* Adjust as needed for mobile devices */
  }
}
